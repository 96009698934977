// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip } from '@mui/material';

// assets
import { IconLogout } from '@tabler/icons';
import useAuth from '../../../../hooks/useAuth';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();

    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                }}
                label="تسجيل الخروج"
                icon={<IconLogout stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                onClick={handleLogout}
                color="primary"
            />
        </>
    );
};

export default ProfileSection;
