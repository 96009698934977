// action - state management
import * as actionTypes from './actions';

export const initialState = {
    profileNotices: [],
    isLogin: false,
    userInfo: {}
};

const okamelReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOT_COMPLETE_PROFILE:
            return {
                ...state,
                profileNotices: action.profileNotices
            };
        case actionTypes.SET_IS_LOGIN:
            return {
                ...state,
                isLogin: action.isLogin
            };
        case actionTypes.SET_USER_LOGIN_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            };
        default:
            return state;
    }
};

export default okamelReducer;
